import type { SortOrder } from '@kevin/mainpage-common/types';

export const sortMethods = {
  alphabetical: (a: string, b: string) => a.localeCompare(b),
  reverseAlphabetical: (a: string, b: string) => b.localeCompare(a),
  ascending: (a: number, b: number) => a - b,
  descending: (a: number, b: number) => b - a,
};

export const sort: Sort = <T extends SortOrder>(
  list?: unknown[],
  order?: T,
  sortKey?: string,
) => {
  if (!list || !order) {
    return list ?? [];
  }

  const sortMethod = sortMethods[order] as (a: unknown, b: unknown) => number;

  return list.sort((a, b) =>
    sortMethod(getKey(a, sortKey), getKey(b, sortKey)),
  );
};

const getKey = (element: unknown, key?: string) =>
  !key ? element : (element as Record<string, unknown>)[key];

type Sort = {
  <T extends SortOrder>(list?: SortType<T>[], order?: T): SortType<T>[];
  <T, O extends SortOrder, K extends SortKey<T, O>>(
    list?: T[],
    order?: O,
    sortKey?: K,
  ): T[];
};

type SortKey<T, O extends SortOrder> = keyof {
  [K in keyof T]: T[K] extends SortType<O> ? K : never;
};

type SortType<T extends SortOrder> = Parameters<(typeof sortMethods)[T]>[0];
