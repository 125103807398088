import React, { ReactNode } from 'react';

import ContextWrapper from '@/context';
import { PageContextMultilingualBase } from '@/types/app/pageContext.type';

import OnStartup from './OnStartup';

type WrapWithContextProps = {
  element: ReactNode;
  props: {
    pageContext?: PageContextMultilingualBase;
  };
};

const wrapWithContext = ({ element, props }: WrapWithContextProps) => {
  return (
    <ContextWrapper
      locale={props?.pageContext?.locale}
      pageContext={props?.pageContext}
    >
      <OnStartup />
      {element}
    </ContextWrapper>
  );
};

export default wrapWithContext;
