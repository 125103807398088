import { navigate } from 'gatsby';
import { NavigationActionTypes } from '@kevin/mainpage-common/types/navigationAction';

import { SanityNavigationAction } from '@/types/app';
import { scrollToElement } from '@/utility/scrollToElement';
import { NavigationActionProps } from '@/types/app/navigationAction.type';

type SanityNavigationActionToProps = (
  navigationAction?: SanityNavigationAction,
) => NavigationActionProps;

/**
 * @deprecated, use sanity ctaButton2, linkItem2
 */

export const sanityNavigationActionToProps: SanityNavigationActionToProps = (
  navigationAction,
) => {
  const { actionType, scrollToId, url } = navigationAction || {};

  let target = undefined;
  let href = undefined;
  let onClick = undefined;

  // TODO: update CtaButtonProps type, replace action with onClick.
  switch (actionType) {
    case NavigationActionTypes.scrollToId: {
      onClick = () =>
        requestAnimationFrame(() => {
          scrollToElement(scrollToId ?? '');
        });
      break;
    }
    case NavigationActionTypes.internalLink: {
      target = '_top' as const;
      href = url;
      onClick = url ? () => navigate(url) : undefined;
      break;
    }
    case NavigationActionTypes.externalLink: {
      target = '_blank' as const;
      href = url;
      onClick = () => window.open(url, '_blank');
      break;
    }
  }

  return {
    onClick,
    href,
    target,
  };
};
