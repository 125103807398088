import { supportedLanguagesObject } from '../resource';
import { constructPath } from './';
import { GetNodeBasePath, I18nLang } from '../types';

type Node = {
  _type: string;
  i18n_lang?: I18nLang;
  slug?: {
    current?: string;
  };
};

type ConstructNodePath = (props: {
  node: Node | undefined;
  getNodeBasePath: GetNodeBasePath | undefined;
}) => string;

export const constructNodePath: ConstructNodePath = ({
  node,
  getNodeBasePath,
}) => {
  if (!node) {
    return '';
  }

  const { _type, i18n_lang, slug } = node;

  const basePath = getNodeBasePath?.({ locale: i18n_lang, type: _type }) || '';

  const { routeParam: routeLocale } =
    (i18n_lang && supportedLanguagesObject[i18n_lang]) || {};

  return constructPath([routeLocale, basePath, slug?.current]);
};
