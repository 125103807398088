import { startCase } from 'lodash';

import { CountryCode } from '../types';
import { camelCaseToLabel } from '../util/changeCase';

const countryOptionsList = Object.entries(CountryCode).map(([name, code]) => ({
  title: camelCaseToLabel(name),
  value: code,
}));

const getCountryName = (value: string): string =>
  startCase(
    countryOptionsList.find((item) => {
      return item.value === value;
    })?.title || value,
  );

export { countryOptionsList, getCountryName };
