import { createContext } from 'react';
import {
  I18nLang,
  LanguageCode,
  CountryCode,
} from '@kevin/mainpage-common/types';
import { parseLocale } from '@kevin/mainpage-common/util';
import { DEFAULT_LOCALE } from '@kevin/mainpage-common/resource';

type I18nContext = {
  locale: I18nLang;
  countryCode?: CountryCode;
  languageCode: LanguageCode;
  localePath: string;
};

export const fillContext = (locale = DEFAULT_LOCALE): I18nContext => ({
  locale,
  ...parseLocale(locale ?? DEFAULT_LOCALE),
});

export const i18nContext = createContext<I18nContext>(fillContext());

export const I18nProvider = i18nContext.Provider;
