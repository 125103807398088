import { StateKey, ColorValue } from './color.types';
import { colorThemePalette } from './color-theme-palette';

export const colorsState: Record<StateKey, Record<ColorValue, string>> = {
  success: {
    base: colorThemePalette.green,
    contrast: colorThemePalette.white,
    tint: colorThemePalette.greenTint,
    shade: colorThemePalette.greenShade,
  },
  warning: {
    base: colorThemePalette.yellow,
    contrast: colorThemePalette.white,
    tint: colorThemePalette.yellowTint,
    shade: colorThemePalette.yellowShade,
  },
  error: {
    base: colorThemePalette.kevinRed,
    contrast: colorThemePalette.white,
    tint: colorThemePalette.kevinRedTint,
    shade: colorThemePalette.kevinRedShade,
  },
};
