import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { UiContext, UiState, Actions } from './uiContext.type';

const initialState: UiState = {
  consentModalOpen: false,
  headerHeight: 0,
  isLowEnd: false,
  homepageBackgroundLoaded: false,
};

export const uiContext = createContext<UiContext>(initialState);

const { Provider } = uiContext;

export const UiProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<UiState>(initialState);

  const toggleConsentModal = useCallback<Actions['toggleConsentModal']>(
    (value) => {
      setState((previousState) => ({
        ...previousState,
        consentModalOpen: value,
      }));
    },
    [],
  );

  const setHeaderHeight = useCallback<Actions['setHeaderHeight']>((value) => {
    setState((previousState) => ({
      ...previousState,
      headerHeight: value,
    }));
  }, []);

  const setIsLowEnd = useCallback<Actions['setIsLowEnd']>((value) => {
    setState((previousState) => ({
      ...previousState,
      isLowEnd: value,
    }));
  }, []);

  const setHomepageBackgroundLoaded = useCallback<
    Actions['setHomepageBackgroundLoaded']
  >((value) => {
    setState((previousState) => ({
      ...previousState,
      homepageBackgroundLoaded: value,
    }));
  }, []);

  return (
    <Provider
      value={{
        ...state,
        toggleConsentModal,
        setHeaderHeight,
        setIsLowEnd,
        setHomepageBackgroundLoaded,
      }}
    >
      {children}
    </Provider>
  );
};

export const useUi = () => useContext(uiContext);
