export const isNotNullish = <T>(
  data: T,
): data is Exclude<T, null | undefined> => data !== undefined && data !== null;

export const isTranslatedString = (
  value: unknown,
): value is CMS.SanityTranslatedString =>
  (value as Record<string, unknown>)?.__typename === 'SanityTranslatedString' ||
  (value as Record<string, unknown>)?._type === 'translatedString';

export const isConditionalString = (
  value: unknown,
): value is CMS.SanityConditionalTranslatedString =>
  (value as Record<string, unknown>)?.__typename ===
    'SanityConditionalTranslatedString' ||
  (value as Record<string, unknown>)?._type === 'conditionalTranslatedString';

export const isTranslatedStrings = (
  value: any,
): value is CMS.SanityTranslatedStrings =>
  value?.__typename === 'SanityTranslatedStrings' &&
  value?.translations?._type === 'translatedStringsList';
