import { GetNodeBasePath, I18nLang } from '../types';
// Keep full router import, otherwise sanity crashes with random error.
import { routes } from '../resource/routes';
import { PageType } from '../types';

export type Node = {
  _type: string;
  i18n_lang?: I18nLang;
  slug?: {
    current?: string;
  };
};

type LocaleToPageSlug = Partial<Record<I18nLang, string>>;

type GetNodeBasePathFactory = (props: {
  blogHome?: Node[] | undefined;
  newsroomHome?: Node[] | undefined;
  securityHome?: Node[] | undefined;
}) => GetNodeBasePath;

export const getNodeBasePathFactory: GetNodeBasePathFactory = ({
  blogHome,
  newsroomHome,
  securityHome,
}) => {
  const localeToBlogHomeSlug = nodesToSlugMap(blogHome);
  const localeToNewsroomHomeSlug = nodesToSlugMap(newsroomHome);
  const localeToSecurityHomeSlug = nodesToSlugMap(securityHome);

  return ({ locale, type }) => {
    switch (type) {
      case 'companyDocument':
        return routes.document;
      case 'presetBlock':
        return routes.presetBlock;
      case PageType.blogPost: {
        return getSlug(locale, localeToBlogHomeSlug);
      }
      case PageType.newsroomPost: {
        return getSlug(locale, localeToNewsroomHomeSlug);
      }
      case PageType.securityPost: {
        return getSlug(locale, localeToSecurityHomeSlug);
      }
      default: {
        if (type.startsWith('blockSettings')) {
          return routes.presetBlock;
        }

        return '';
      }
    }
  };
};

const nodesToSlugMap = (nodes?: Node[]): LocaleToPageSlug => {
  return Object.fromEntries(
    nodes?.map(({ i18n_lang, slug }) => [i18n_lang, slug?.current || '']) || [],
  );
};

const getSlug = (locale: I18nLang | undefined, slugMap: LocaleToPageSlug) => {
  if (!locale) {
    return '';
  }

  return (
    slugMap[locale] ||
    // Some localized pages are created from default.
    // Importing DEFAULT_LOCALE breaks sanity, issues with resolving modules (?).
    slugMap[I18nLang.default] ||
    ''
  );
};
