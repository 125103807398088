import { createContext } from 'react';

import type { PageContextMultilingual } from '@/types/app/pageContext.type';

export type PageContextContext<T = CMS.Page> = Partial<
  PageContextMultilingual<T>
>;

export const pageContextContext = createContext<PageContextContext>({});

export const PageContextProvider = pageContextContext.Provider;
