export const colorOldPalette = {
  // Kevin red
  kevinRed: '#ff0020',
  kevinRedTint: '#ffc4c1',
  kevinRedShade: '#aa2720',

  // Blue
  blue: '#6e8bbf',
  blueTint: '#d3dbeb',
  blueShade: '#495c7f',

  // Green
  green: '#71df69',
  greenTint: '#a6efb4',
  greenShade: '#1e7a30',

  // Yellow
  yellow: '#ffbe30',
  yellowTint: '#ffdb93',
  yellowShade: '#c68820',

  // Grays
  gray100: '#f2f2f2',
  gray200: '#f0ece6',
  gray300: '#d5d4d0',
  gray400: '#b5b2ab',
  gray500: '#a09e98',
  gray600: '#606060',
  gray700: '#424242',

  // Neutral
  white: '#fff',
  black: '#191919',
};
