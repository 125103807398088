import { NeutralKey, ColorValue } from './color.types';
import { colorThemePalette } from './color-theme-palette';
import { colorOldPalette } from './color-old-palette';

export const colorsNeutral: Record<NeutralKey, Record<ColorValue, string>> = {
  white: {
    base: colorOldPalette.white,
    contrast: colorOldPalette.black,
    tint: colorOldPalette.white,
    shade: colorThemePalette['gray-4'],
  },
  black: {
    base: colorOldPalette.black,
    contrast: colorOldPalette.white,
    tint: colorOldPalette.gray700,
    shade: colorOldPalette.black,
  },
};
