type ConstructPath = (
  subPaths: (string | undefined | null)[],
  omitTrailingSlash?: boolean,
) => string;

export const constructPath: ConstructPath = (
  subPaths,
  omitTrailingSlash = false,
) => {
  const path =
    subPaths.reduce((accumulator, currentValue) => {
      if (typeof accumulator !== 'string') {
        return '';
      }

      if (!currentValue) {
        return accumulator;
      }

      return `${accumulator}/${removeSurroundingSlashes(currentValue)}`;
    }, '') || '';

  if (omitTrailingSlash) {
    return path;
  }

  return addTrailingSlash(path);
};

const removeSurroundingSlashes = (value: string): string =>
  value.replace(/^\/+/, '').replace(/\/+$/, '');

const addTrailingSlash = (value: string): string =>
  value.endsWith('/') ? value : `${value}/`;
