import { getGatsbyImageData } from 'gatsby-source-sanity';
import imageUrlBuilder from '@sanity/image-url';
import { GatsbyImageDataArgs } from 'gatsby-source-sanity/lib-es5/images/getGatsbyImageProps';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import { AllowOneOfProperties } from '@/types/app';

const BREAKPOINTS = [
  320, 654, 768, 1024, 1366, 1600, 1920, 2048, 2560, 3440, 3840, 4096,
] as const;

export type Widths = [(typeof BREAKPOINTS)[number], number][];

type AllSrcSetOptions = {
  maxWidth: number;
  maxHeight: number;
  widths: Widths;
};

export type SrcSetOptions = AllowOneOfProperties<AllSrcSetOptions>;

const sanityConfig = {
  projectId: process.env.SANITY_STUDIO_API_PROJECT_ID as string,
  dataset: process.env.SANITY_STUDIO_API_DATASET as string,
};

type ConstructGatsbyImageData = (props: {
  id: string;
  gatsbyImageConfig?: GatsbyImageDataArgs;
  srcSetOptions?: SrcSetOptions;
}) => IGatsbyImageData | undefined;

const builder = imageUrlBuilder(sanityConfig);

export const constructGatsbyImageData: ConstructGatsbyImageData = ({
  id,
  gatsbyImageConfig,
  srcSetOptions,
}) => {
  const imageData =
    getGatsbyImageData(
      id,
      {
        layout:
          gatsbyImageConfig?.width || gatsbyImageConfig?.height
            ? undefined
            : 'fullWidth',
        ...(gatsbyImageConfig || {}),
      },
      sanityConfig,
    ) ?? undefined;

  if (srcSetOptions && imageData?.images?.fallback?.srcSet) {
    const newSet = generateSrcSet(id, srcSetOptions);

    if (newSet) {
      imageData.images.fallback.srcSet = newSet;
    }
  }

  return imageData;
};

// Generates srcSet replacement for srcSet generated by `gatsby-source-sanity`.
// Because `gatsby-source-sanity` does not account how wide image on screen is,
// it treats all images as full width across the screen.
export const generateSrcSet = (id: string, srcSetOptions: SrcSetOptions) => {
  // example id url:
  // image-a24fb28382d54cc1476731c7b652dc2bb0c35f17-3019x1200-png

  const dimensions = id.split('-')[2];

  if (!dimensions) {
    return;
  }

  const [innateWidth, innateHeight] = dimensions
    .split('x')
    .map((num) => parseInt(num, 10));

  if (!innateWidth || !innateHeight) {
    return;
  }

  const aspectRatio = innateWidth / innateHeight;

  const urls: string[] = [];

  const { maxWidth } = srcSetOptions;
  const { maxHeight } = srcSetOptions;

  if (maxWidth) {
    BREAKPOINTS.forEach((breakpoint) => {
      if (breakpoint <= innateWidth && breakpoint <= maxWidth) {
        urls.push(createBreakpointUrl(id, breakpoint, breakpoint, aspectRatio));
      }
    });

    // ts requires `includes` argument to be part of array...
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!BREAKPOINTS.includes(maxWidth)) {
      urls.push(createBreakpointUrl(id, maxWidth, maxWidth, aspectRatio));
    }
  } else if (maxHeight) {
    const widthForMaxHeight = Math.round(maxHeight * aspectRatio);

    BREAKPOINTS.forEach((breakpoint) => {
      if (breakpoint <= widthForMaxHeight) {
        urls.push(createBreakpointUrl(id, breakpoint, breakpoint, aspectRatio));
      }
    });

    // ts requires `includes` argument to be part of array...
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!BREAKPOINTS.includes(widthForMaxHeight)) {
      urls.push(
        createBreakpointUrl(
          id,
          widthForMaxHeight,
          widthForMaxHeight,
          aspectRatio,
        ),
      );
    }
  } else if (srcSetOptions.widths) {
    srcSetOptions.widths
      .sort((a, b) => a[0] - b[0])
      .forEach(([breakpoint, width]) => {
        if (Number(width) <= innateWidth) {
          urls.push(createBreakpointUrl(id, breakpoint, width, aspectRatio));
        }
      });
  }

  return urls.join(',\n');
};

const createBreakpointUrl = (
  id: string,
  breakpoint: number,
  width: number,
  aspectRatio: number,
) =>
  `${builder
    .image(id)
    .width(width)
    .height(Math.round(width / aspectRatio))
    .auto('format')
    .url()} ${breakpoint}w`;
