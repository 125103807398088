export const DEPRECATED_breakPoints = {
  wideMobile: 480,
  tablet: 768,
  wideTablet: 896,
  tabletLandscape: 1024,
  laptop: 1280,
  desktop: 1440,
  widescreen: 1920,
};

export const breakPoints = {
  mobile: 600,
  tablet: 768,
  desktop: 1025,
  desktopMdBp: 1501,
};

export type DEPRECATED_Breakpoint = keyof typeof DEPRECATED_breakPoints;
export type Breakpoint = keyof typeof breakPoints;

export const DEPRECATED_breakpointQueries = Object.fromEntries(
  Object.entries(DEPRECATED_breakPoints).map(([name, size]) => [
    name,
    `(min-width: ${size}px)`,
  ]),
) as Record<DEPRECATED_Breakpoint, string>;
export const breakpointQueries = Object.fromEntries(
  Object.entries(breakPoints).map(([name, size]) => [
    name,
    `(min-width: ${size}px)`,
  ]),
) as Record<Breakpoint, string>;
