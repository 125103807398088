export enum I18nLang {
  // Default
  default = 'en',
  // Austria
  en_AT = 'en_AT',
  de_AT = 'de_AT',
  // Belgium
  en_BE = 'en_BE',
  nl_BE = 'nl_BE',
  fr_BE = 'fr_BE',
  // Bulgaria
  en_BG = 'en_BG',
  bg_BG = 'bg_BG',
  // Croatia
  en_HR = 'en_HR',
  hr_HR = 'hr_HR',
  // Cyprus
  en_CY = 'en_CY',
  el_CY = 'el_CY',
  // Czechia
  cs_CZ = 'cs_CZ',
  en_CZ = 'en_CZ',
  // Denmark
  da_DK = 'da_DK',
  en_DK = 'en_DK',
  // Estonia
  et_EE = 'et_EE',
  en_EE = 'en_EE',
  // Finland
  fi_FI = 'fi_FI',
  en_FI = 'en_FI',
  // French
  fr_FR = 'fr_FR',
  en_FR = 'en_FR',
  // Germany
  de_DE = 'de_DE',
  en_DE = 'en_DE',
  // Greece
  en_GR = 'en_GR',
  el_GR = 'el_GR',
  // Ireland
  en_IE = 'en_IE',
  // Hungary
  hu_HU = 'hu_HU',
  en_HU = 'en_HU',
  // Iceland
  is_IS = 'is_IS',
  en_IS = 'en_IS',
  // Italy
  it_IT = 'it_IT',
  en_IT = 'en_IT',
  // Latvia
  lv_LV = 'lv_LV',
  en_LV = 'en_LV',
  // Lithuania
  lt_LT = 'lt_LT',
  en_LT = 'en_LT',
  // Luxembourg
  en_LU = 'en_LU',
  fr_LU = 'fr_LU',
  de_LU = 'de_LU',
  // Malta
  en_MT = 'en_MT',
  mt_MT = 'mt_MT',
  // Netherlands
  nl_NL = 'nl_NL',
  en_NL = 'en_NL',
  // Norway
  no_NO = 'no_NO',
  en_NO = 'en_NO',
  // Polish
  pl_PL = 'pl_PL',
  en_PL = 'en_PL',
  // Portugal
  pt_PT = 'pt_PT',
  en_PT = 'en_PT',
  // Romania
  ro_RO = 'ro_RO',
  en_RO = 'en_RO',
  // Slovakia
  sk_SK = 'sk_SK',
  en_SK = 'en_SK',
  // Slovenia
  en_SI = 'en_SI',
  sl_SI = 'sl_SI',
  // Spain
  es_ES = 'es_ES',
  en_ES = 'en_ES',
  // Sweden
  sv_SE = 'sv_SE',
  en_SE = 'en_SE',
  // United Kingdom
  en_GB = 'en_GB',
}
