export const capitalize = <T extends string | undefined>(value: T): T =>
  value && (`${value[0]?.toUpperCase() || ''}${value.slice(1)}` as T);

export const uncapitalize = <T extends string | undefined>(value: T): T =>
  value && (`${value[0]?.toLowerCase() || ''}${value.slice(1)}` as T);

export const camelCaseToLabel = (value: string): string => {
  const withSpaces = value.replace(/([A-Z])/g, ' $1').trim();
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
};
