import { LanguageCode } from '../types';

// https://www.loc.gov/standards/iso639-2/php/code_list.php
// Used T variant where applicable.

const languageCodeToISO639_2Map = {
  [LanguageCode.Bulgarian]: 'bul',
  [LanguageCode.Croatian]: 'hrv',
  [LanguageCode.Czech]: 'ces',
  [LanguageCode.Danish]: 'dan',
  [LanguageCode.Dutch]: 'nld',
  [LanguageCode.Greek]: 'ell',
  [LanguageCode.English]: 'eng',
  [LanguageCode.Estonian]: 'est',
  [LanguageCode.Finnish]: 'fin',
  [LanguageCode.French]: 'fra',
  [LanguageCode.German]: 'deu',
  [LanguageCode.Hungarian]: 'hun',
  [LanguageCode.Icelandic]: 'isl',
  [LanguageCode.Italian]: 'ita',
  [LanguageCode.Latvian]: 'lav',
  [LanguageCode.Lithuanian]: 'lit',
  [LanguageCode.Maltese]: 'mlt',
  [LanguageCode.Norwegian]: 'nor',
  [LanguageCode.Polish]: 'pol',
  [LanguageCode.Portuguese]: 'por',
  [LanguageCode.Romanian]: 'ron',
  [LanguageCode.Slovak]: 'slk',
  [LanguageCode.Slovenian]: 'slv',
  [LanguageCode.Spanish]: 'spa',
  [LanguageCode.Swedish]: 'swe',
};

type CountryCodeToName = (countryCode: LanguageCode) => string;

export const languageCodeToISO639_2T: CountryCodeToName = (countryCode) => {
  return languageCodeToISO639_2Map[countryCode];
};
