import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/gatsby';

import { isBrowser } from '@/utility';

import HandleSegmentEvents from './HandleSegmentEvents';

if (isBrowser) {
  let disableSentryForTests;

  try {
    disableSentryForTests = JSON.parse(
      localStorage.getItem('disableSentryForTests') as string,
    );
  } catch {
    localStorage.removeItem('disableSentryForTests');
  }

  if (disableSentryForTests) {
    Sentry.init({
      beforeSend() {
        return null;
      },
    });
  }
}

const wrapWithProvider = ({ element }: { element: ReactNode }) => {
  return (
    <>
      <HandleSegmentEvents />
      {element}
    </>
  );
};

export default wrapWithProvider;
