import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CountryCode } from '@kevin/mainpage-common/types';

import { geolocation } from '@/constants';

type UserContext = {
  countryCode?: CountryCode;
  loadingCountryCode: boolean;
};

export type CountryCodeFetchResponse = {
  country_code: CountryCode;
  client_ip: string;
};

const initialState: UserContext = {
  countryCode: undefined,
  loadingCountryCode: false,
};

export const userContext = createContext<UserContext>(initialState);

const { Provider } = userContext;

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [userState, setUseState] = useState(initialState);

  const getCountryCode = useCallback(async () => {
    try {
      setUseState({
        loadingCountryCode: true,
        countryCode: undefined,
      });

      const response = await fetch(geolocation);

      const data: CountryCodeFetchResponse = await response.json();
      const countryCode = data?.country_code || '';

      setUseState({
        loadingCountryCode: false,
        countryCode,
      });
    } catch (error) {
      setUseState({
        loadingCountryCode: false,
        countryCode: undefined,
      });
    }
  }, []);

  useEffect(() => {
    getCountryCode();
  }, [getCountryCode]);

  return <Provider value={userState}>{children}</Provider>;
};

export const useUser = () => useContext(userContext);
