export enum ParagraphSize {
  extraSmall = 'xs',
  small = 'sm',
  normal = 'md',
  large = 'lg',
}

// Yes, this is not an enum on purpose.
// Numeric enum members are additionally reverse mapped at runtime, meaning
// something like `enum Weight { regular = 400 }` will result in the object
// `{ regular: 400, 400: 'regular' }`, making using enumToOptionsList with it
// useless.
export const ParagraphWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 800,
};

export type ParagraphWeightKey = keyof typeof ParagraphWeight;
