type PreventWordBreak = (text: string) => string;

// Prevent word break on dashes, e.g. e-commerce.
export const preventWordBreak: PreventWordBreak = (text = '') =>
  text
    ?.split(' ')
    .map((word) => {
      if (word.includes('-')) {
        return `<nobr>${word}</nobr>`;
      }

      return word;
    })
    .join(' ');

// Current must have zeros in front,
// e.g. 01/10, but 1/9.
// Zero count depends on max string length.
export const formatWithLeadingZeros = (currentIndex: number, max: number) => {
  const rawCurrent = String(currentIndex + 1);
  const currentAndTotalLengthDiff = String(max).length - rawCurrent.length;

  return rawCurrent.padStart(currentAndTotalLengthDiff + 1, '0');
};
