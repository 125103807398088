const generateRandomString = (length?: number) => {
  let random = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(36);

  if (
    random.length === length ||
    typeof length !== 'number' ||
    Number.isNaN(length)
  )
    return random;

  while (random.length < length) random += generateRandomString();

  return random.slice(0, length);
};

type Opts = { length?: number; prefix?: string };

export const generateId = ({ length, prefix }: Opts = {}) =>
  `${prefix ? `${prefix}-` : ''}${generateRandomString(length)}`;
