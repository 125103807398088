import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import {
  GreenhouseDepartmentList,
  GreenhouseJob,
} from '@/types/app/greenhouseAPI';
import { greenhouseBoardAPIBase } from '@/constants/endpoints';

import {
  CareersState,
  CareersContext,
  Actions,
  JobState,
} from './careersContext.type';

const initialState: CareersState = {
  careerSelection: {
    department: null,
    location: null,
  },
  departments: {
    data: [],
    loading: true,
    hasError: false,
  },
  jobData: {},
};

export const careersContext = createContext<CareersContext>(initialState);

const { Provider } = careersContext;

export const CareersProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<CareersState>(initialState);

  const setCareerSelection = useCallback<Actions['setCareerSelection']>(
    (value: Partial<CareersState['careerSelection']>) => {
      setState((previousState) => ({
        ...previousState,
        careerSelection: {
          ...previousState.careerSelection,
          ...value,
        },
      }));
    },
    [],
  );

  const setDepartment = useCallback<Actions['setDepartment']>((value) => {
    setState((previousState) => ({
      ...previousState,
      departments: value,
    }));
  }, []);

  const setJobState = (jobId: string, jobState: JobState) => {
    setState((prevState) => ({
      ...prevState,
      jobData: {
        ...prevState.jobData,
        [jobId]: jobState,
      },
    }));
  };

  const getOpenPositions = useCallback<Actions['getOpenPositions']>(
    async (boardName: string) => {
      try {
        if (!boardName) {
          return;
        }

        setDepartment({
          data: [],
          loading: true,
          hasError: false,
        });

        const response = await fetch(
          `${greenhouseBoardAPIBase}/${boardName}/departments`,
        );
        const openPositions = (await response.json())
          .departments as GreenhouseDepartmentList;

        setDepartment({
          data: openPositions,
          loading: false,
          hasError: false,
        });
      } catch (error) {
        setDepartment({
          data: [],
          loading: false,
          hasError: true,
        });
      }
    },
    [],
  );

  const getJobData = useCallback<Actions['getJobData']>(
    async (jobId: string, boardName: string) => {
      try {
        if (!jobId || !boardName) {
          return;
        }

        setJobState(jobId, {
          loading: true,
          hasError: false,
          data: null,
        });

        const response = await fetch(
          `${greenhouseBoardAPIBase}/${boardName}/jobs/${jobId}?questions=true`,
        );

        const jobData = (await response.json()) as GreenhouseJob;

        if (!jobData || jobData?.error) {
          setJobState(jobId, {
            loading: false,
            hasError: true,
            data: null,
          });

          return;
        }

        setJobState(jobId, {
          loading: false,
          hasError: false,
          data: jobData,
        });
      } catch (error) {
        setJobState(jobId, {
          loading: false,
          hasError: true,
          data: null,
        });
      }
    },
    [],
  );

  return (
    <Provider
      value={{
        ...state,
        setCareerSelection,
        getOpenPositions,
        getJobData,
      }}
    >
      {children}
    </Provider>
  );
};

export const useCareers = () => useContext(careersContext);
