import { useEffect } from 'react';

import { useUi } from '@/context/ui';

const LOW_END_MEMORY_COUNT = 4;
const LOW_END_CORE_COUNT = 4;

export const useIsLowEnd = () => {
  const { setIsLowEnd } = useUi();

  useEffect(() => {
    const deviceMemory =
      (navigator as Navigator & { deviceMemory?: number }).deviceMemory ||
      LOW_END_MEMORY_COUNT + 1;

    const isLowEndHardware =
      deviceMemory <= LOW_END_MEMORY_COUNT ||
      navigator.hardwareConcurrency <= LOW_END_CORE_COUNT;

    setIsLowEnd?.(isLowEndHardware);
  }, [setIsLowEnd]);
};
