module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-a40a5a5146/3/buildhome/.yarn_cache/gatsby-plugin-manifest-npm-5.13.0-d134c631ce-abb051d218.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kevin","icon":"src/assets/new_design_favicon.png","icons":[{"src":"/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/favicon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"/favicons/favicon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/favicon-192x192.png","sizes":"192x192","type":"image/png"}],"start_url":"/","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d413847655d46097467844a15091fb21"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-react-helmet-async-virtual-7d6f82274d/3/buildhome/.yarn_cache/gatsby-plugin-react-helmet-async-npm-1.2.3-9c8c369ef0-0504784ea9.zip/node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/@sentry-gatsby-virtual-c31b907330/3/buildhome/.yarn_cache/@sentry-gatsby-npm-7.92.0-c209000e1d-ca629b4966.zip/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://5927e502672f4c7494ca08f6636af094@o956240.ingest.sentry.io/6483510","sampleRate":0.7,"environment":"production","enabled":true,"sourcemaps":{"assets":["public/**"]},"integrations":[{"name":"BrowserTracing","_hasSetTracePropagationTargets":false,"options":{"idleTimeout":1000,"finalTimeout":30000,"heartbeatInterval":5000,"markBackgroundTransactions":true,"startTransactionOnLocationChange":true,"startTransactionOnPageLoad":true,"enableLongTask":true,"_experiments":{},"traceFetch":true,"traceXHR":true,"enableHTTPTimings":true,"tracingOrigins":["localhost",{}],"tracePropagationTargets":["localhost",{}]}}],"tracesSampleRate":0.05},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-05200166b9/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
