export const routes = {
  home: '/',
  document: '/docs',
  presetBlock: '/block',
};

export const previewBasePaths = {
  blogPost: 'blog',
  newsroomPost: 'newsroom',
  securityPost: 'security',
};
