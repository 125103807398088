import { CountryCode, I18nLang, LanguageCode } from '../../types';

type ParseLocale = (locale: I18nLang) => {
  languageCode: LanguageCode;
  countryCode: CountryCode | undefined;
  localePath: string;
};

export const parseLocale: ParseLocale = (locale) => {
  const [languageCode, countryCode] = locale.split('_') as [
    LanguageCode,
    CountryCode | undefined,
  ];

  return {
    languageCode,
    countryCode,
    localePath:
      locale === I18nLang.default
        ? ''
        : locale.replace('_', '-').toLocaleLowerCase(),
  };
};

export const localeToRouteParam = (locale: I18nLang): string =>
  locale.replace('_', '-').toLowerCase();
