export const setBodyNoScroll = (noScroll: boolean) => {
  if (noScroll) {
    // document.body.style.top = `-${window.scrollY}px`;
    // document.body.style.position = 'fixed';
    document.body.style.overflowY = 'hidden';
  } else {
    // const scrollY = document.body.style.top;
    // document.body.style.position = '';
    // document.body.style.top = '';
    // window.scrollTo(0, parseInt(scrollY || '0') * -1);
    document.body.style.overflowY = 'auto';
  }
};

let scrollTimer: number;

export const smoothScrollTo = (
  endX: number,
  endY: number,
  duration: number,
) => {
  const startX = window.scrollX || window.pageXOffset;
  const startY = window.scrollY || window.pageYOffset;
  const distanceX = endX - startX;
  const distanceY = endY - startY;
  const startTime = new Date().getTime();

  // Easing function
  const easeInOutQuart = (
    time: number,
    from: number,
    distance: number,
    easeDuration: number,
  ) => {
    if ((time /= easeDuration / 2) < 1) {
      return (distance / 2) * time * time * time * time + from;
    }

    return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  };

  // Cancel previous, to prevent jumps
  if (scrollTimer) {
    clearInterval(scrollTimer);
  }

  scrollTimer = window.setInterval(() => {
    const time = new Date().getTime() - startTime;
    const newX = easeInOutQuart(time, startX, distanceX, duration);
    const newY = easeInOutQuart(time, startY, distanceY, duration);

    if (time >= duration) {
      window.clearInterval(scrollTimer);
    }
    window.scrollTo(newX, newY);
  }, 1000 / 60); // 60 fps
};

export const scrollToTargetOffset = (el: HTMLElement, offset = 0) => {
  const elPosition = el.getBoundingClientRect().top;
  const offsetPosition = elPosition + window.pageYOffset - offset;

  const duration = Math.min(2000, Math.abs(elPosition + offset) / 0.5);

  smoothScrollTo(0, offsetPosition, duration);

  return { duration };
};

export const inArray = (needle: string, haystack: string[]) =>
  haystack.indexOf(needle) !== -1;
