import { LanguageCode } from '../types';

const languageCodeToNameMap: Record<LanguageCode, string> = {
  [LanguageCode.Bulgarian]: 'Български',
  [LanguageCode.Croatian]: 'Hrvatski',
  [LanguageCode.Czech]: 'Čeština',
  [LanguageCode.Danish]: 'Dansk',
  [LanguageCode.Dutch]: 'Nederlands',
  [LanguageCode.English]: 'English',
  [LanguageCode.Estonian]: 'Eesti',
  [LanguageCode.Finnish]: 'Suomi',
  [LanguageCode.French]: 'Français',
  [LanguageCode.German]: 'Deutsch',
  [LanguageCode.Greek]: 'Ελληνικά',
  [LanguageCode.Hungarian]: 'Magyar',
  [LanguageCode.Icelandic]: 'Íslensku',
  [LanguageCode.Italian]: 'Italiano',
  [LanguageCode.Latvian]: 'Latviešu',
  [LanguageCode.Lithuanian]: 'Lietuvių',
  [LanguageCode.Maltese]: 'bil-Malti',
  [LanguageCode.Norwegian]: 'Norsk',
  [LanguageCode.Polish]: 'Polski',
  [LanguageCode.Portuguese]: 'Português',
  [LanguageCode.Romanian]: 'Român',
  [LanguageCode.Slovak]: 'Slovenčina',
  [LanguageCode.Slovenian]: 'Slovenščina',
  [LanguageCode.Spanish]: 'Español',
  [LanguageCode.Swedish]: 'Svenska',
};

type LanguageCodeToLocalName = (countryCode: LanguageCode) => string;

export const languageCodeToLocalName: LanguageCodeToLocalName = (
  countryCode,
) => {
  return languageCodeToNameMap[countryCode];
};
