import { CountryCode } from './countryCode';

export const callingCode: {
  [K in Exclude<CountryCode, CountryCode.Default>]: number;
} = {
  [CountryCode.Austria]: 43,
  [CountryCode.Belgium]: 32,
  [CountryCode.Bulgaria]: 359,
  [CountryCode.Croatia]: 385,
  [CountryCode.Cyprus]: 357,
  [CountryCode.Czechia]: 420,
  [CountryCode.Denmark]: 45,
  [CountryCode.Estonia]: 372,
  [CountryCode.Finland]: 358,
  [CountryCode.France]: 33,
  [CountryCode.Germany]: 49,
  [CountryCode.Greece]: 30,
  [CountryCode.Hungary]: 36,
  [CountryCode.Iceland]: 354,
  [CountryCode.Ireland]: 353,
  [CountryCode.Italy]: 39,
  [CountryCode.Latvia]: 371,
  [CountryCode.Lithuania]: 370,
  [CountryCode.Luxembourg]: 352,
  [CountryCode.Malta]: 356,
  [CountryCode.Netherlands]: 31,
  [CountryCode.Norway]: 47,
  [CountryCode.Poland]: 48,
  [CountryCode.Portugal]: 351,
  [CountryCode.Romania]: 40,
  [CountryCode.SanMarino]: 378,
  [CountryCode.Serbia]: 381,
  [CountryCode.Slovakia]: 421,
  [CountryCode.Slovenia]: 386,
  [CountryCode.Spain]: 34,
  [CountryCode.Sweden]: 46,
  [CountryCode.Switzerland]: 41,
  [CountryCode.UnitedKingdom]: 44,
};
