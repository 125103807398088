import { CountryCode } from '../types';

export const countryCodeToNameMap: Partial<Record<CountryCode, string>> = {
  [CountryCode.Austria]: 'Austria',
  [CountryCode.Belgium]: 'Belgium',
  [CountryCode.Bulgaria]: 'Bulgaria',
  [CountryCode.Croatia]: 'Croatia',
  [CountryCode.Cyprus]: 'Cyprus',
  [CountryCode.Czechia]: 'Czechia',
  [CountryCode.Default]: 'Default',
  [CountryCode.Denmark]: 'Denmark',
  [CountryCode.Estonia]: 'Estonia',
  [CountryCode.Finland]: 'Finland',
  [CountryCode.France]: 'French',
  [CountryCode.Germany]: 'Germany',
  [CountryCode.Greece]: 'Greece',
  [CountryCode.Hungary]: 'Hungary',
  [CountryCode.Iceland]: 'Iceland',
  [CountryCode.Ireland]: 'Ireland',
  [CountryCode.Italy]: 'Italy',
  [CountryCode.Latvia]: 'Latvia',
  [CountryCode.Lithuania]: 'Lithuania',
  [CountryCode.Luxembourg]: 'Luxembourg',
  [CountryCode.Malta]: 'Malta',
  [CountryCode.Netherlands]: 'Netherlands',
  [CountryCode.Norway]: 'Norway',
  [CountryCode.Poland]: 'Poland',
  [CountryCode.Portugal]: 'Portugal',
  [CountryCode.Romania]: 'Romania',
  [CountryCode.SanMarino]: 'SanMarino',
  [CountryCode.Serbia]: 'Serbia',
  [CountryCode.Slovakia]: 'Slovakia',
  [CountryCode.Slovenia]: 'Slovenia',
  [CountryCode.Spain]: 'Spain',
  [CountryCode.Sweden]: 'Sweden',
  [CountryCode.Switzerland]: 'Switzerland',
  [CountryCode.UnitedKingdom]: 'UnitedKingdom',
};

type CountryCodeToName = (countryCode: CountryCode) => string | undefined;

export const countryCodeToName: CountryCodeToName = (countryCode) => {
  return countryCodeToNameMap[countryCode];
};
