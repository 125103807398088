import { CountryCode, I18nLang, LanguageCode } from '../types';
import { countryCodeToName, parseLocale, localeToRouteParam } from '../util';
import { languageCodeToName } from '../util/languageCodeToName';

export const DEFAULT_LOCALE = I18nLang.default;
export const DEFAULT_LANGUAGE_NAME = languageCodeToName(LanguageCode.English);
export const DEFAULT_LANGUAGE_CODE = LanguageCode.English;
export const DEFAULT_COUNTRY_CODE = CountryCode.Default;

export const supportedLanguagesArray: Language[] = Object.values(I18nLang).map(
  (locale) => {
    if (locale === DEFAULT_LOCALE) {
      return {
        code: DEFAULT_LOCALE,
        routeParam: undefined,
        name: { en: countryCodeToName(CountryCode.Default) || 'Default' },
      };
    }

    const { countryCode, languageCode } = parseLocale(locale);

    const countryName =
      (countryCode && countryCodeToName(countryCode)) ||
      'Unknown or missing country code';
    const languageName =
      (languageCode && languageCodeToName(languageCode)) ||
      'Unknown or missing language code';

    return {
      code: locale,
      routeParam: localeToRouteParam(locale),
      name: { en: `${countryName} - ${languageName}` },
    };
  },
);

export const supportedLanguagesObject = supportedLanguagesArray.reduce(
  (prev, current) => ({
    ...prev,
    [current.code]: current,
  }),
  {},
) as Record<I18nLang, Language>;

interface Language {
  code: I18nLang;
  routeParam: string | undefined;
  name: {
    en: string;
  };
}
