import { LanguageCode } from '../types';

const languageCodeToNameMap = {
  [LanguageCode.Bulgarian]: 'Bulgarian',
  [LanguageCode.Croatian]: 'Croatian',
  [LanguageCode.Czech]: 'Czech',
  [LanguageCode.Danish]: 'Danish',
  [LanguageCode.Dutch]: 'Dutch',
  [LanguageCode.Greek]: 'Greek',
  [LanguageCode.English]: 'English',
  [LanguageCode.Estonian]: 'Estonian',
  [LanguageCode.Finnish]: 'Finnish',
  [LanguageCode.French]: 'French',
  [LanguageCode.German]: 'German',
  [LanguageCode.Hungarian]: 'Hungarian',
  [LanguageCode.Icelandic]: 'Icelandic',
  [LanguageCode.Italian]: 'Italian',
  [LanguageCode.Latvian]: 'Latvian',
  [LanguageCode.Lithuanian]: 'Lithuanian',
  [LanguageCode.Maltese]: 'Maltese',
  [LanguageCode.Norwegian]: 'Norwegian',
  [LanguageCode.Polish]: 'Polish',
  [LanguageCode.Portuguese]: 'Portuguese',
  [LanguageCode.Romanian]: 'Romanian',
  [LanguageCode.Slovak]: 'Slovakian',
  [LanguageCode.Slovenian]: 'Slovenian',
  [LanguageCode.Spanish]: 'Spanish',
  [LanguageCode.Swedish]: 'Swedish',
};

type CountryCodeToName = (countryCode: LanguageCode) => string;

export const languageCodeToName: CountryCodeToName = (countryCode) => {
  return languageCodeToNameMap[countryCode];
};
