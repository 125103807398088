import { ThemeKey, ColorValue } from './color.types';
import { colorOldPalette } from './color-old-palette';

export const colorsOldTheme: Record<ThemeKey, Record<ColorValue, string>> = {
  primary: {
    base: colorOldPalette.kevinRed,
    contrast: colorOldPalette.gray200,
    tint: colorOldPalette.kevinRedTint,
    shade: colorOldPalette.kevinRedShade,
  },
  cards: {
    base: colorOldPalette.blue,
    contrast: colorOldPalette.gray200,
    tint: colorOldPalette.blueTint,
    shade: colorOldPalette.blueShade,
  },
  light: {
    base: colorOldPalette.gray200,
    contrast: colorOldPalette.gray600,
    tint: colorOldPalette.gray100,
    shade: colorOldPalette.gray300,
  },
  medium: {
    base: colorOldPalette.gray400,
    contrast: colorOldPalette.gray200,
    tint: colorOldPalette.gray300,
    shade: colorOldPalette.gray500,
  },
  dark: {
    base: colorOldPalette.gray600,
    contrast: colorOldPalette.gray200,
    tint: colorOldPalette.gray500,
    shade: colorOldPalette.gray700,
  },
};
