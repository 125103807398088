import React, { FC } from 'react';
import { I18nLang } from '@kevin/mainpage-common/types';

import { fillContext, I18nProvider } from '@/context/i18n';
import { PageContextProvider } from '@/context/pageContext';
import { PageContextMultilingualBase } from '@/types/app/pageContext.type';
import { UserProvider } from '@/context/user/userContext';
import { CareersProvider } from '@/context/careers';
import { UiProvider } from '@/context/ui';
import { MetaProvider } from '@/context/meta';
// NEW_CONTEXT

type ContextWrapperProps = {
  children: React.ReactNode;
  locale: I18nLang | undefined;
  pageContext?: PageContextMultilingualBase;
};

const ContextWrapper: FC<ContextWrapperProps> = ({
  children,
  locale,
  pageContext,
}) => {
  return (
    <I18nProvider value={fillContext(locale)}>
      <PageContextProvider value={pageContext || {}}>
        <UserProvider>
          <UiProvider>
            <CareersProvider>
              <MetaProvider>{children}</MetaProvider>
            </CareersProvider>
          </UiProvider>
        </UserProvider>
      </PageContextProvider>
    </I18nProvider>
  );
};

export default ContextWrapper;
