import { CountryCode } from '../types';

export const countryCodeToNameMap: Partial<Record<CountryCode, string>> = {
  [CountryCode.Austria]: 'Österreich',
  [CountryCode.Belgium]: 'België',
  [CountryCode.Bulgaria]: 'България',
  [CountryCode.Croatia]: 'Hrvatska',
  [CountryCode.Cyprus]: 'Κύπρος',
  [CountryCode.Czechia]: 'Česko',
  [CountryCode.Default]: 'International',
  [CountryCode.Denmark]: 'Danmark',
  [CountryCode.Estonia]: 'Eesti',
  [CountryCode.Finland]: 'Suomi',
  [CountryCode.France]: 'France',
  [CountryCode.Germany]: 'Deutschland',
  [CountryCode.Greece]: 'Ελλάς',
  [CountryCode.Hungary]: 'Magyarország',
  [CountryCode.Iceland]: 'Ísland',
  [CountryCode.Ireland]: 'Éire',
  [CountryCode.Italy]: 'Italia',
  [CountryCode.Latvia]: 'Latvija',
  [CountryCode.Lithuania]: 'Lietuva',
  [CountryCode.Luxembourg]: 'Lëtezebuerg',
  [CountryCode.Malta]: 'Malta',
  [CountryCode.Netherlands]: 'Nederland',
  [CountryCode.Norway]: 'Norge',
  [CountryCode.Poland]: 'Polska',
  [CountryCode.Portugal]: 'Portugal',
  [CountryCode.Romania]: 'România',
  [CountryCode.SanMarino]: 'San Marino',
  [CountryCode.Serbia]: 'Србија',
  [CountryCode.Slovakia]: 'Slovensko',
  [CountryCode.Slovenia]: 'Slovenija',
  [CountryCode.Spain]: 'España',
  [CountryCode.Sweden]: 'Sverige',
  [CountryCode.Switzerland]: 'Suisse',
  [CountryCode.UnitedKingdom]: 'United Kingdom',
};

type CountryCodeToLocalName = (countryCode: CountryCode) => string | undefined;

export const countryCodeToLocalName: CountryCodeToLocalName = (countryCode) => {
  return countryCodeToNameMap[countryCode];
};
