const baseUnit = 1;
const unitType = 'rem';

export const fontSizes: FontSizes = {
  xs: `${baseUnit * 0.875}${unitType}`, // 0.875rem | 14px
  sm: `${baseUnit * 1}${unitType}`, // 1rem | 16px
  md: `${baseUnit * 1.125}${unitType}`, // 1.125rem | 18px
  lg: `${baseUnit * 1.5}${unitType}`, // 1.5rem | 24px
  xl: `${baseUnit * 1.8125}${unitType}`, // 1.18125rem | 29px
  '2xl': `${baseUnit * 2.1875}${unitType}`, // 2.1875rem | 35px
  '3xl': `${baseUnit * 2.8125}${unitType}`, // 2.8125rem | 45px
};

type FontSizes = {
  [key in FontSizeKey]: string;
};

export type FontSizeKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
