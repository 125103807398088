import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import type { WithContext, Thing as JsonLDThing } from 'schema-dts';

type MetaContext = {
  structuredData: StructuredData[];
  addStructuredData: (data: StructuredData) => () => void;
};

export type Thing = Extract<JsonLDThing, { '@type': string }>;
export type StructuredData = WithContext<Thing>;

const initialState: MetaContext = {
  structuredData: [],
  addStructuredData: () => () => void 0,
};

const metaContext = createContext<MetaContext>(initialState);
const { Provider } = metaContext;

export const MetaProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [structuredData, setStructuredData] = useState<StructuredData[]>([]);

  const addStructuredData = useCallback((data: StructuredData) => {
    setStructuredData((current) => [...current, data]);

    return () => {
      setStructuredData((current) => current.filter((v) => v !== data));
    };
  }, []);

  return (
    <Provider value={{ structuredData, addStructuredData }}>
      {children}
    </Provider>
  );
};

export const useMetaContext = () => useContext(metaContext);
