export enum PageType {
  blogHome = 'blogHome',
  blogPost = 'blogPost',
  companyDocument = 'companyDocument',
  notFound = 'notFound',
  pageABVersions = 'pageABVersions',
  home3 = 'home3',
  careers2 = 'careers2',
  contacts2 = 'contacts2',
  genericPage2 = 'genericPage2',
  newsroomHomepage = 'newsroomHomepage',
  newsroomPost = 'newsroomPost',
  securityHomepage = 'securityHomepage',
  securityPost = 'securityPost',
  // NEW_PAGE_TYPE
}
