import { isBrowser } from './isBrowser';

type Listener = (scrollPos: number) => void;
type Disconnect = () => void;

const listeners = new Set<Listener>();

export const onScroll = (listener: Listener): Disconnect => {
  if (!isBrowser) {
    () => void 0;
  }

  listeners.add(listener);

  if (listeners.size === 1) {
    window.addEventListener('scroll', scrollListener, { passive: true });
  }

  return () => {
    listeners.delete(listener);

    if (listeners.size === 0) {
      window.removeEventListener('scroll', scrollListener);
    }
  };
};

const scrollListener = () => {
  const scrollPos = window.scrollY;
  listeners.forEach((listener) => {
    listener(scrollPos);
  });
};
