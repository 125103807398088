export enum FormTypes {
  salesforce = 'salesforce',
  salesforceSupport = 'salesforceSupport',
  customerIo = 'customerio',
  zendesk = 'zendesk',
}

export enum ZendeskFormSubtypes {
  question = 'question',
  complaint = 'complaint',
}

export enum FormFieldType {
  text = 'text',
  textBox = 'text-box',
  phoneNumber = 'phone-number',
  list = 'list',
  listMultiselect = 'list-multiselect',
  file = 'file',
  hidden = 'hidden',
}

export const formFieldTypes = Object.values(FormFieldType);

export const CustomFieldId = 'custom';

export enum CustomerIoFieldId {
  // Specific to hero form
  firstName = 'First Name',
  workEmail = 'Work Email',
  company = 'Company',
  jobTitle = 'Job title',
  industry = 'Industry',
  // Specific to newsletters
  email = 'email',
  // Other
  leadSource = 'lead_source',
  ipCountryCode = 'ip_country_code',
  custom = 'custom',
}

export enum SalesforceDefaultFieldId {
  firstName = 'first_name',
  lastName = 'last_name',
  email = 'email',
  company = 'company',
  leadSource = 'lead_source',
  industry = 'industry',
  revenue = 'revenue',
  employees = 'employees',
  country = 'Country__c',
  city = 'city',
  state = 'state',
  street = 'street',
  zip = 'zip',
  salutation = 'salutation',
  title = 'title',
  url = 'url',
  phone = 'phone',
  mobile = 'mobile',
  description = 'description',
  recordType = 'recordType',
  webFormSource = 'webFormSource__c',
  products = 'products__c',
  custom = 'custom',
}

export enum ZendeskFieldId {
  // Fields that can be mapped to zendesk request fields
  subject = 'subject',
  description = 'description',
  customerName = 'customerName',
  email = 'email',
  files = 'files',

  // Field specific to complaint form
  from = 'from',
  legalEntityCode = 'legalEntityCode',
  address = 'address',
  reporterName = 'reporterName',
  reporterSurname = 'reporterSurname',
  reporterPosition = 'reporterPosition',
  otherDetails = 'otherDetails',

  custom = 'custom',
}

export enum SalesforceSupportFieldId {
  // Needed in faq and complaint forms
  email = 'email',
  name = 'name',
  subject = 'subject',
  description = 'description',

  // Needed in complaint form
  reporterName = 'reporterName__c',
  reporterSurname = 'reporterSurname__c',
  reporterPosition = 'reporterPosition__c',
  from = 'from',
  legalEntityCode = 'legalEntityCode__c',
  address = 'suppliedAddress__c',
  otherDetails = 'internalComments__c',

  custom = 'custom',
}

export enum SalesForceSupportFormSubtypes {
  Complaint = 'Complaint',
  Request = 'Request',
}
