import { ThemeKey, ColorValue } from './color.types';
import { colorThemePalette } from './color-theme-palette';

export const colorNextTheme: Record<ThemeKey, Record<ColorValue, string>> = {
  primary: {
    base: colorThemePalette.kevinRed,
    contrast: colorThemePalette.white,
    tint: colorThemePalette.kevinRedTint,
    shade: colorThemePalette.kevinRedShade,
  },
  cards: {
    base: colorThemePalette.blue,
    contrast: colorThemePalette.white,
    tint: colorThemePalette.blueTint,
    shade: colorThemePalette.blueShade,
  },
  light: {
    base: colorThemePalette.babyBlue,
    contrast: colorThemePalette.blueDark,
    tint: colorThemePalette['gray-5'],
    shade: colorThemePalette['gray-3'],
  },
  medium: {
    base: colorThemePalette['gray-2'],
    contrast: colorThemePalette.white,
    tint: colorThemePalette['gray-3'],
    shade: colorThemePalette['gray-1'],
  },
  dark: {
    base: colorThemePalette.blueDark,
    contrast: colorThemePalette.white,
    tint: colorThemePalette['gray-1'],
    shade: colorThemePalette.black,
  },
};
