export enum SanityCtaButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  link = 'link',
}

export enum SanityCtaButtonSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export enum SanityCtaButtonContext {
  light = 'light',
  dark = 'dark',
}
