import {
  KEVIN_DEMO_URL,
  KEVIN_DOCUMENTATION_URL,
} from '@kevin/mainpage-common/constants/urls';

import { isBrowser } from '@/utility/isBrowser';

// Dont change values !
// Segment functionality relies on them.
export enum SegmentEventNames {
  webClickCareerJob = 'webClickCareerJob',
  webClickDemo = 'webClickDemo',
  webClickPaymentFlowSwitch = 'webClickPaymentFlowSwitch',
  webClickTopMenu = 'webClickTopMenu',
  webClickViewDocumentation = 'webClickViewDocumentation',
  webEmailClick = 'webEmailClick',
  webFooterClickSocialIcons = 'webFooterClickSocialIcons',
  webLeadFormSubmit = 'webLeadFormSubmit',
  webDemoPageArrowClick = 'webDemoPageArrowClick',
  webDemoPageNavigationClick = 'webDemoPageNavigationClick',
  webDemoPageCountryClick = 'webDemoPageCountryClick',
  webLanguageBarFooterContinueClick = 'webLanguageBarFooterContinueClick',
  webLanguageBarMobileContinueClick = 'webLanguageBarMobileContinueClick',
  webLanguageBarSuggestionsContinueClick = 'webLanguageBarSuggestionsContinueClick',
  webLanguageBarSuggestionsCloseClick = 'webLanguageBarSuggestionsCloseClick',
  webLanguageBarFooterFindClick = 'webLanguageBarFooterFindClick',
  webLanguageBarMobileFindClick = 'webLanguageBarMobileFindClick',
  webLanguageBarSuggestionsFindClick = 'webLanguageBarSuggestionsFindClick',
}

const segmentIsActive = () => isBrowser && window?.analytics?.initialized;

export const addSegmentScript = (writeKey: string) => {
  if (segmentIsActive()) {
    return;
  }

  const script = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="nLR9hnw2RhML4qUVWjLW04PdhvAgLSkw";;analytics.SNIPPET_VERSION="4.15.3";
      analytics.load("${writeKey}");
      analytics.page();
    }}();`;
  const scriptElement = document.createElement('script');
  scriptElement.innerHTML = script;
  document.head.append(scriptElement);
};

export const segmentPage = () => {
  window.analytics?.page?.();
};

type SegmentTrackProps = {
  event: string;
  properties?: Record<string, string | undefined>;
};

export const segmentTrack = ({ event, properties }: SegmentTrackProps) => {
  if (!isBrowser) {
    return;
  }

  window.analytics?.track?.(event, properties);
};

type SegmentTrackForm = (props: { formData: FormData }) => void;

export const segmentTrackForm: SegmentTrackForm = ({ formData }) => {
  const properties: Record<string, string> = {};

  formData.forEach((value, key) => {
    // last_name should always be filtered out.
    // It causes lead form to be treated as sensitive
    // information and "messes with reports".
    if (
      typeof value === 'string' &&
      key !== 'last_name' &&
      key !== 'first_name'
    ) {
      // Segment uses property url as page context url.
      properties[key === 'url' ? 'form_url' : key] = value;
    }
  });

  segmentTrack({
    event: SegmentEventNames.webLeadFormSubmit,
    properties,
  });
};

type SegmentTrackClickProps = {
  href?: string;
  event?: string;
  properties?: Record<string, string | undefined>;
};

const demoUrlPattern = new URL(KEVIN_DEMO_URL).hostname;
const documentationUrlPattern = new URL(KEVIN_DOCUMENTATION_URL).hostname;

export const segmentTrackClick = ({
  event,
  properties,
  href,
}: SegmentTrackClickProps) => {
  if (event) {
    segmentTrack({
      event,
      properties,
    });
  } else if (href?.includes(documentationUrlPattern)) {
    segmentTrack({
      event: SegmentEventNames.webClickViewDocumentation,
    });
  } else if (href?.includes(demoUrlPattern)) {
    segmentTrack({
      event: SegmentEventNames.webClickDemo,
    });
  }
};
