export enum Hero3Type {
  landing = 'landing',
  secondary = 'secondary',
}

export enum Hero3PaddingAdjustment {
  homePage = 'homePage',
  demoPage = 'demoPage',
  contactsPage = 'contactsPage',
  documentsPage = 'documentsPage',
  aboutUsPage = 'aboutUsPage',
  articlesHomepage = 'articlesHomepage',
}
