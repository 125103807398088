import { isBrowser } from '@/utility';

function putSearchParamInLocalStorage(name: string, value: string | null) {
  localStorage.setItem(
    name,
    JSON.stringify({
      value,
      expiryDate: Date.now() + 90 * 24 * 60 * 60 * 1000,
    }),
  );
}

export const addCampaignParams = () => {
  if (isBrowser) {
    const searchParams = new URLSearchParams(location.search);
    const gclId = searchParams.get('gclid');
    const gclSrc = searchParams.get('gclsrc');
    const utmCampaign = searchParams.get('utm_campaign');
    const utmSource = searchParams.get('utm_source');
    const utmContent = searchParams.get('utm_content');
    const isGclsrcValid = !gclSrc || gclSrc.indexOf('aw') !== -1;

    if (gclId && isGclsrcValid) {
      putSearchParamInLocalStorage('gclId', gclId);
    }

    if (utmCampaign) {
      putSearchParamInLocalStorage('utmCampaign', utmCampaign);

      if (utmSource) {
        putSearchParamInLocalStorage('utmSource', utmSource);
      }

      if (utmContent) {
        putSearchParamInLocalStorage('utmContent', utmContent);
      }
    }
  }
};
