import { ThemeKey, StateKey, NeutralKey, ColorValue } from './color.types';
import { colorsOldTheme } from './color-old';
import { colorsState } from './color-state';
import { colorsNeutral } from './color-neutral';
import { colorNextTheme } from './color-theme';

export const colors = {
  theme: (key: ThemeKey, value: ColorValue): string => {
    return colorNextTheme[key][value];
  },
  state: (key: StateKey, value: ColorValue): string => {
    return colorsState[key][value];
  },
  neutral: (key: NeutralKey, value: ColorValue): string => {
    return colorsNeutral[key][value];
  },
  old: (key: ThemeKey, value: ColorValue): string => {
    return colorsOldTheme[key][value];
  },
};
