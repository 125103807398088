export const colorThemePalette = {
  // Red
  kevinRed: '#ff0020',
  kevinRedTint: '#ff8984',
  kevinRedShade: '#da3731',
  // Blue
  blue: '#5d80fe',
  blueShade: '#4464d7',
  blueTint: '#bdc7eb',
  babyBlue: '#f0f5fc',
  blueDark: '#0b1e42',
  // Yellow
  yellow: '#ffd645',
  yellowShade: '#eeba00',
  yellowTint: '#ffe27d',
  // Green
  green: '#6adf72',
  greenShade: '#33af5d',
  greenTint: '#a5f6bc',
  // Gray
  'gray-1': '#5f6470',
  'gray-2': '#949aa3',
  'gray-3': '#dbdee7',
  'gray-4': '#f2f2f2',
  'gray-5': '#fbfbfe',
  // Neutral
  white: '#fff',
  black: '#000',
};
