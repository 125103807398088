import React from 'react';

import { useIsLowEnd } from '@/wrapWithProvider/hooks/useIsLowEnd';
import { addCampaignParams } from '@/utility/analytics/campaignParams';

const OnStartup = () => {
  useIsLowEnd();
  addCampaignParams();

  return null;
};

export default React.memo(OnStartup);
