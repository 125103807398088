export enum LanguageCode {
  Bulgarian = 'bg',
  Croatian = 'hr',
  Czech = 'cs',
  Danish = 'da',
  Dutch = 'nl',
  Greek = 'el',
  English = 'en',
  Estonian = 'et',
  Finnish = 'fi',
  French = 'fr',
  German = 'de',
  Hungarian = 'hu',
  Icelandic = 'is',
  Italian = 'it',
  Latvian = 'lv',
  Lithuanian = 'lt',
  Maltese = 'mt',
  Norwegian = 'no',
  Polish = 'pl',
  Portuguese = 'pt',
  Romanian = 'ro',
  Slovak = 'sk',
  Slovenian = 'sl',
  Spanish = 'es',
  Swedish = 'sv',
}
