import React, { useEffect } from 'react';
import { globalHistory } from '@gatsbyjs/reach-router';

import {
  SegmentEventNames,
  segmentPage,
  segmentTrack,
} from '@/utility/analytics/segment';

// Clicking on same link fires event multiple times,
// even though page don't change.
let previousPathname = '';

const HandleSegmentEvents = () => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        (event.target as Element | undefined)?.tagName.toLowerCase() !== 'a'
      ) {
        return;
      }

      if ((event.target as HTMLAnchorElement)?.href?.startsWith('mailto:')) {
        segmentTrack({
          event: SegmentEventNames.webEmailClick,
        });
      }
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, []);

  useEffect(() => {
    return globalHistory.listen(({ location }) => {
      if (location.pathname !== previousPathname) {
        previousPathname = location.pathname;
        segmentPage();
      }
    });
  }, []);

  return null;
};

export default React.memo(HandleSegmentEvents);
